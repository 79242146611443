<template>
	<div>
		<field title="患者称呼" type="1"></field>


		<van-search placeholder="请输入搜索关键词" v-model="value" />
		<div id="asa">
			1212
		</div>
			

	</div>
</template>

<script>
	import field from '@/components/patient/field';
	import { Search } from 'vant';
export default {
  name: 'home',
  
  components: {

	field,
	Search
	},
	data() {
		return {
			value: ''
		}
	},
  methods:{
	
  },
  mounted() {
  
  }
}
</script>

<style scoped="scoped">
	div{
		background: red;
		width: 100%;
	}
	#asa{
		width: 100%;
		height: 200px;
		background: red;
	}
</style>
